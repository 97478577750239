const isProduction = window.location.hostname.includes('platinumrx.in');

const apiConfig = {
  production: {
    apiUrl: 'https://internal.platinumrx.in',
  },
  staging: {
    apiUrl: 'https://backendstaging.platinumrx.in',
  },
};

const environment = isProduction ? 'production' : 'staging';
const { apiUrl } = apiConfig[environment];
export default { apiUrl };
