import React, { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { updateAgentStatus } from 'utils/api';
import styles from './ChangeCRMAgentStatus.module.scss';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

const ChangeCRMAgentStatus = () => {
  const [status, setStatus] = useState('');
  const [showOptions, setShowOptions] = useState(false); // State to manage dropdown visibility

  useEffect(() => {
    const localStatus = localStorage.getItem('platinumRxDashboardAgentStatus');
    if (localStatus) {
      setStatus(JSON.parse(localStatus));
    }
  }, []);

  const handleStatusChange = async (selectedStatus) => {
    setStatus(selectedStatus);
    setShowOptions(false); // Hide options after selection

    try {
      const data = { status: selectedStatus };
      await updateAgentStatus()(data);
      localStorage.setItem(
        'platinumRxDashboardAgentStatus',
        JSON.stringify(selectedStatus)
      );
      enqueueSnackbar(`Agent status updated to ${selectedStatus}`, {
        variant: 'success',
      });
    } catch (error) {
      console.error('Error updating agent status:', error);
      enqueueSnackbar(
        error?.response?.data?.message || 'Error updating status',
        { variant: 'error' }
      );
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'ACTIVE':
        return 'green';
      case 'INACTIVE':
        return 'red';
      case 'BREAK':
        return 'orange';
      default:
        return 'gray';
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.statusDisplay}
        style={{
          backgroundColor: getStatusColor(status),
          color: '#fff',
        }}
        onClick={() => setShowOptions(!showOptions)}
      >
        {status || 'Not Set'}
        {showOptions ? <ArrowDropUp /> : <ArrowDropDown />}
      </div>
      {showOptions && (
        <div className={styles.statusOptions}>
          {['ACTIVE', 'INACTIVE', 'BREAK'].map((option) => (
            <div
              className={styles.options}
              key={option}
              onClick={() => handleStatusChange(option)}
              style={{
                borderColor: getStatusColor(option),
              }}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChangeCRMAgentStatus;
