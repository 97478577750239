import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { fetchIssueOrders } from 'utils/api';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const OrderListTL = () => {
  const [orders, setOrders] = useState([]);
  const [orderIdFilter, setOrderIdFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchOrdersData = async () => {
    try {
      const data = {
        data: {
          orderId: Number(orderIdFilter),
        },
        params: {
          skip: 0,
          limit: 100,
        },
      };
      setLoading(true);
      const response = await fetchIssueOrders()(data);
      if (response.data.success === 1) {
        setOrders(response.data.data.orders);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdersData();
  }, []);

  const filteredOrders = orders.filter((order) =>
    orderIdFilter ? order.orderId.toString() === orderIdFilter : true
  );

  const columns = [
    { field: 'orderId', headerName: 'Order ID', width: 130 },
    { field: 'rackIdentifier', headerName: 'Rack Identifier', width: 150 },
    { field: 'barcodeSerial', headerName: 'Box Code', width: 130 },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
    { field: 'userId', headerName: 'User ID', width: 120 },
    { field: 'status', headerName: 'Status', width: 200 },
  ];

  const rows = filteredOrders.map((order) => ({
    id: order.orderId,
    orderId: order.orderId,
    userId: order.userId,
    status: order.status,
    createdAt: new Date(order.createdAt).toLocaleString(),
    barcodeSerial: order.barcodeSerial,
    rackIdentifier: order.rackIdentifier,
  }));

  const handleRowClick = (params) => {
    navigate(`/wms/picker/tl/issue-order-details/${params.row.orderId}`, {
      state: { orderId: params.row.orderId },
    });
  };

  return (
    <div className='home'>
      <h1>
        <b>Picker TL Order Listing</b>
      </h1>
      <div className='filter-header'>
        <TextField
          variant='outlined'
          type='number'
          fullWidth
          label='Filter by Order ID'
          onChange={(e) => setOrderIdFilter(e.target.value.replace(/\D/g, ''))} // Update filter state, allowing only numeric characters
        />
      </div>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={100}
        loading={loading}
        autoHeight
        disableSelectionOnClick
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default OrderListTL;
