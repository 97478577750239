import React from 'react';
import { Checkbox, Chip } from '@mui/material';
import styles from '../cartDetails.module.scss';

const ItemDetails = ({
  item,
  handleCheckboxChange,
  checkedItems,
  showCheck,
  orderRemarks,
}) => {
  const {
    itemCartData: {
      drugName = '',
      itemTotalDiscountPrice = 0,
      itemTotalPrice = 0,
      quantity = 0,
    } = {},
    itemCatalogData: {
      heroImage = '',
      drugCategory = '',
      manufacturerName = '',
      packForm = '',
      packQuantityValue = 0,
      drugForm = '',
    } = {},
  } = item || {};

  const issues = orderRemarks
    .filter((remark) => remark.masterDrugCode === item.itemCartData?.drugCode)
    .map((remark) => remark.remark);

  return (
    <div className={styles.item_container}>
      <div className={styles.content}>
        <div>
          <div
            style={{
              backgroundImage: `url("${heroImage}")`,
            }}
            className={styles.item_img}
          />
          {drugCategory === 'RX' && <div className={styles.rx_icon}>Rx</div>}
        </div>
        <div className={styles.item_details}>
          <div className={styles.drug_Name}>{drugName}</div>
          <div className={styles.img_container}>
            <div className={styles.manufacturer_name}>
              {manufacturerName?.split(' ')[0]}
            </div>
            <div className={styles.vertical_divider} />
            <div className={styles.manufacturer_name}>
              {packForm} of {packQuantityValue} {drugForm}
            </div>
          </div>
          <div className={styles.price_container}>
            <div className={styles.discounted_price}>
              ₹
              {itemTotalDiscountPrice?.toLocaleString('en-GB', {
                timeZone: 'UTC',
              })}
            </div>
            <div className={styles.mrp_price}>
              ₹
              {itemTotalPrice?.toLocaleString('en-GB', {
                timeZone: 'UTC',
              })}
            </div>
            <div className={styles.item_qty}>
              Qty:
              {quantity}
            </div>
          </div>
          <div className={styles.issues_container}>
            {issues.map((issue, index) => (
              <Chip
                sx={{ fontSize: '10px', padding: '2px' }}
                key={index}
                label={issue}
                color='error'
              />
            ))}
          </div>
        </div>
      </div>
      <div>
        {showCheck && (
          <div className={styles.checkbox_container}>
            <Checkbox
              checked={checkedItems?.includes(item)}
              onChange={() => handleCheckboxChange(item)}
              color='primary'
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 28,
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemDetails;
