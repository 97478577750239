import { Switch, Box } from '@mui/material';
import { Brightness7, Brightness4 } from '@mui/icons-material';
import styles from './toggleThemeButton.module.scss';

const ToggleThemeButton = ({ themeMode, toggleTheme }) => {
  return (
    <Box className={styles.main}>
      <Switch
        className={styles.switch}
        checked={themeMode === 'dark'}
        onChange={toggleTheme}
        color='primary'
        inputProps={{ 'aria-label': 'Toggle Theme' }}
      />
      {themeMode === 'light' ? (
        <Brightness7 className={styles.lightIcon} />
      ) : (
        <Brightness4 className={styles.darkIcon} />
      )}
    </Box>
  );
};

export default ToggleThemeButton;
