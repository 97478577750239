import { da } from 'date-fns/locale';
import createAxios from './createAxios';

const browserAxios = createAxios();

export const InternalUserLogin =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/internal-users/login', data });

export const sendOtp =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/auth/sendOtp', data });

export const getPharmacistOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/pharmacistOrders`,
      params: data,
    });

export const getDoctorOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/doctorOrders`,
      params: data,
    });

export const getAllOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/internal/fetchOrders`,
      params: data,
    });

export const getAllPharmacists =
  (axios = browserAxios) =>
  () =>
    axios.get({ url: '/internal-users/getAllPharmacists' });

export const getAllDoctors =
  (axios = browserAxios) =>
  () =>
    axios.get({ url: '/internal-users/getAllDoctors' });

export const getPastPrescriptions =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: '/digitizedPrescriptions/pastPrescriptions',
      params: data,
    });

export const assignPharmacist =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/orders/assignPharmacist', data });

export const assignDoctor =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/orders/assignDoctor', data });

export const markPharmacistUnavailable =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/internal-users/changePharmacistAvailability/${data.pharmacistId}`,
      data: { active: data.active },
    });

export const markDoctorUnavailable =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/internal-users/changeDoctorAvailability/${data.doctorId}`,
      data: { active: data.active },
    });

export const createDigitizedPrx =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/digitizedPrescriptions/bulkCreate', data });

export const deleteDigitizedPrx =
  (axios = browserAxios) =>
  () =>
    axios.delete({ url: '/digitizedPrescriptions/2' });

export const getOrderDetails =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/orders/internal/fetchOrderDetails/${data.orderId}`,
      params: data.params,
    });

export const getUserPrescriptions =
  (axios = browserAxios) =>
  (data) =>
    axios.get({
      url: `/files/prescriptions`,
      params: { cartId: data?.cartId },
    });

export const cloneCart =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/orders/internal/cloneOrderCart/${data.orderId}` });

export const getDoctorInfo =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: '/ops/internal/doctor', params: data });

export const getCartDetail =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: `/cart/internal/fetchCartFromId/${data.cartId}` });

export const addItemInCartByQuantity =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/cart/internal/${data.userId}/setItemQuantity?cartGeneratedBy=doctor&cartId=${data.cartId}`,
      data,
    });

export const updateOrderStatus =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/orders/updateDoctorReviewStatus', data });

export const signAndSend =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/ops/internal/createDoctorOrOpsCart', data });

export const createPrxForDoctorCart =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: '/ops/internal/generatePrescriptionForCart', data });

export const placeOrderWithNewCart =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/payment/internal/cloneOrderWithNewCart/${data.orderId}`,
      data,
    });

export const makeCall =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `orders/callPatient/${data.id}` });

export const fetchUserDetails =
  (axios = browserAxios) =>
  (id) =>
    axios.get({
      url: `/users/fetchuserdetails`,
      headers: { user_id: id },
    });

export const addUpdateUserPatient =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/users/internal/addUpdateUserPatient`, data });

export const getProcurementItems =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/items`,
      data: data?.data,
      params: data?.params,
    });

export const createPurchaseOrder =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/createPurchaseOrder`,
      data: data,
    });

export const createDraftPurchaseOrder =
  (axios = browserAxios) =>
  (data) =>
    axios.put({
      url: `/procurement/draftPurchaseOrderItem`,
      data: data,
    });

export const getPendingOrdersOfDrug =
  (axios = browserAxios) =>
  (masterDrugCode) =>
    axios.get({
      url: `/procurement/items/${masterDrugCode}/orders`,
    });

export const getWarehousePendingOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/warehousePendingOrders`,
      params: data.params,
      data: data.data,
    });

export const getPendingOrderItems =
  (axios = browserAxios) =>
  (orderId) =>
    axios.get({
      url: `/procurement/orderItemProcurement/${orderId}`,
    });

export const draftPurchaseOrderItem =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/draftPurchaseOrderItems`,
      params: data.params,
      data: data.data,
    });

export const deleteDraftPurchaseOrderItem =
  (axios = browserAxios) =>
  (id) =>
    axios.delete({
      url: `/procurement/draftPurchaseOrderItem/${id}`,
    });

export const getPurchaseOrderItems =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/purchaseOrderItems`,
      params: data.params,
      data: data.data,
    });

export const getPurchaseOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `procurement/purchaseOrders`,
      params: data.params,
      data: data.data,
    });

export const createPurchaseOrderItems =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/createPurchaseOrder`,
      params: data,
    });

export const updatePurchaseOrderStatus =
  (axios = browserAxios) =>
  (data) =>
    axios.put({
      url: `/procurement/purchaseOrderItem/${data.id}`,
      data: data.data,
    });

export const getDrugReorderPoints =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/drugReorderPoints`,
      params: data.params,
      data: data.data,
    });

export const putDrugReorderPoint =
  (axios = browserAxios) =>
  (data) =>
    axios.put({
      url: `/procurement/drugReorderPoint`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const getReorderSuggestions =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/procurement/reorderSuggestions`,
      params: data.params,
      data: data.data,
    });

export const MarkProcurementOrderAsQCDone =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/ops/markOrderAsQcDone`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const getPendingWarehouseOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `orders/v2/fetchPendingWarhouseOrders`,
      data: data,
    });

export const getWareHouseOrderDetails =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/orders/v2/internal/warehouseOrder/${data.orderId}` });

export const MarkProcurementSplitOrderAsQCDone =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/ops/v2/markOrderAsQcDone`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const UploadExcelFile =
  (axios = browserAxios) =>
  (data) =>
    axios.put({
      url: `/procurement/upload/draftPurchaseOrderItem`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const deletePurchaseOrder =
  (axios = browserAxios) =>
  (data) =>
    axios.delete({ url: `/procurement/purchaseOrder`, data: data });

export const markAsClosedBulk =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({ url: `/procurement/close/purchaseOrderItems`, data: data });

export const getEvents =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/events/user`, params: data.params, data: data.data });

export const getTasks =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/tasks`, params: data?.params, data: data?.data });

export const getCustomerTasks =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/tasks/customer`,
      params: { ...data?.params },
      data: data?.data,
    });

export const patchDisposition =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/tasks/disposition/`,
      data: data.data,
      headers: {
        user_id: data.userId,
        source: 'cc-dashboard',
      },
    });

export const getTasksforAdmin =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/tasks/all`,
      data: data?.data,
      params: data?.params,
    });
  };

export const getAgents =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/internal-users/list`, data: data });

export const assignOrUpdateOwner =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/tasks/assignOrUpdateOwner`, data: data });

export const AcceptTask =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({ url: `/tasks/status`, data: data });

export const getTaskDetails =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: `/tasks/${data.taskId}` });

export const getAdminTaskDetails =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: `/tasks/admin/${data.taskId}` });

export const subscribeToWebNotifications =
  (axios = browserAxios) =>
  () =>
    axios.get({ url: `/webNotifications/subscribe` });

export const getCampaigns =
  (axios = browserAxios) =>
  (params) =>
    axios.get({ url: `/campaign`, params: params });

export const createTask =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/tasks/new`, data: data });

export const sendWhatsappMessage =
  (axios = browserAxios) =>
  (data) =>
    axios.post({ url: `/gupshup/whatsappTemplateMessage`, data: data });

export const uploadPurchaseOrderFile =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/procurement/purchaseOrder/${data.id}`,
      data: data.data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

export const updateAgentStatus =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({ url: `/internal-users/status`, data: data });

export const autoAcceptOrder =
  (axios = browserAxios) =>
  (data) =>
    axios.get({ url: `/picker/order` });

export const scanBoxCode =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/picker/acceptOrderAndAssignBarcode`,
      data: data,
    });

export const pickUpDone =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/picker/picked/${data?.orderId}`,
    });

export const raisePickerIssues =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/picker/raiseIssue`,
      data: data,
    });

export const fetchIssueOrders =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/picker/tl/pickingIssueOrders`,
      params: data.params,
      data: data.data,
    });

export const unassignItem =
  (axios = browserAxios) =>
  (data) =>
    axios.post({
      url: `/picker/tl/unassignPicker/${data.orderId}`,
    });

export const updateIssueAPI =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/orders/remarks/${data.id}`,
      data: data,
    });

export const deleteIssueAPI =
  (axios = browserAxios) =>
  (data) =>
    axios.delete({
      url: `/orders/remarks/${data.id}`,
    });

export const bulkCloseTasks =
  (axios = browserAxios) =>
  (data) =>
    axios.patch({
      url: `/tasks/ignore`,
      data: data,
    });

export const bulkClosePOs =
  (axios = browserAxios) =>
  (data) =>
    axios.delete({
      url: `/procurement/bulk/draftPurchaseOrderItem`,
      data: data,
    });

export const fetchAllDispositions =
  (axios = browserAxios) =>
  (data) => {
    return axios.post({
      url: `/tasks/dispositions`,
      data: data?.data,
      params: data?.params,
    });
  };
