import React, { useEffect, useState } from 'react';
import styles from './cartDetails.module.scss';
import ItemDetails from './ItemDetails';
import { Button } from '@mui/material';

const CartDetails = ({
  orderDetails,
  WareHouseOrderItems,
  handleCheckboxChange,
  checkedItems,
  location,
  hideHeader,
}) => {
  const data = orderDetails && orderDetails?.orderItems;
  const splitData = WareHouseOrderItems && WareHouseOrderItems;
  const [showAll, setShowAll] = useState(true);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const itemsWithIssues = data?.filter((item) => {
    const issues = orderDetails?.orderRemarks.filter(
      (remark) => remark.masterDrugCode === item.itemCartData?.drugCode
    );
    return issues.length > 0;
  });

  useEffect(() => {
    if (location == 'tl' && itemsWithIssues?.length > 0) {
      setShowAll(false);
    }
  }, [location, itemsWithIssues]);

  return (
    <>
      {!hideHeader && <h1>Order Details</h1>}
      <div className={styles.details_container}>
        {location == 'tl' && !showAll && itemsWithIssues?.length > 0 && (
          <>
            {itemsWithIssues.map((item, index) => (
              <div key={index}>
                <ItemDetails
                  item={item}
                  orderRemarks={orderDetails?.orderRemarks}
                  handleCheckboxChange={handleCheckboxChange}
                  checkedItems={checkedItems}
                  showCheck={location == 'tl' ? false : true}
                />
                {index !== itemsWithIssues.length - 1 && (
                  <div className={styles.horizontal_divider} />
                )}
              </div>
            ))}
          </>
        )}

        {showAll && data?.length > 0 && (
          <>
            {data.map((item, index) => (
              <div key={index}>
                <ItemDetails
                  item={item}
                  orderRemarks={orderDetails?.orderRemarks}
                  handleCheckboxChange={handleCheckboxChange}
                  checkedItems={checkedItems}
                  showCheck={location == 'qc' ? true : false}
                />
                {index !== data.length - 1 && (
                  <div className={styles.horizontal_divider} />
                )}
              </div>
            ))}
          </>
        )}

        {location == 'tl' &&
          itemsWithIssues?.length > 0 &&
          data?.length > itemsWithIssues?.length && (
            <Button onClick={toggleShowAll}>
              {showAll ? 'View Less' : 'View All'}
            </Button>
          )}
        {splitData?.length > 0 &&
          splitData.map((item, index) => (
            <div key={index}>
              <ItemDetails
                item={item}
                orderRemarks={orderDetails?.orderRemarks}
                handleCheckboxChange={handleCheckboxChange}
                checkedItems={checkedItems}
                showCheck={location == 'tl' ? false : true}
              />
              {index !== splitData.length - 1 && (
                <div className={styles.horizontal_divider} />
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default CartDetails;
