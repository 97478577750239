import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  Autocomplete,
  TextField,
  FormControl,
  Grid,
  IconButton,
} from '@mui/material';
import { CheckCircle, AssignmentTurnedIn } from '@mui/icons-material';
import { getTasks, AcceptTask, getCampaigns } from 'utils/api';
import dayjs from 'dayjs';
import styles from './tasks.module.scss';
import CustomDateRangePicker from 'components/CustomElements/CustomDatepicker';
import { useApp } from 'context/AppContext';
import PageLoader from 'components/Loaders/PageLoader';
import { enqueueSnackbar } from 'notistack';

const CRMTasksPage = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const {
    campaignIds,
    setCampaignIds,
    statusFilters,
    setStatusFilters,
    minCartValue,
    setMinCartValue,
    maxCartValue,
    setMaxCartValue,
    userPhoneNumber,
    setUserPhoneNumber,
  } = useApp();

  const navigate = useNavigate();

  const statusOptions = {
    1: 'PENDING',
    5: 'ASSIGNED',
    10: 'IN_PROGRESS',
    20: 'COMPLETED',
    50: 'CLOSED',
  };

  const handleAcceptTask = async (taskId, status) => {
    try {
      const data = {
        taskId: taskId,
        status: status,
      };
      await AcceptTask()(data);
      fetchTasks();
    } catch (error) {
      console.error('Error accepting task:', error);
      enqueueSnackbar(error.response.data.error_message, {
        variant: 'error',
      });
    }
  };

  const handleConfirmStatusChange = (taskId, userId) => {
    navigate(`/crm-dashboard/lead-history/${userId}/${taskId}`, {
      state: { activeTab: 'conversation' },
    });
  };

  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      const params = { skip: 0, limit: 15 };
      const response = await getCampaigns()(params);
      setCampaignOptions(response.data.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTasks = async () => {
    try {
      const data = {
        data: {
          campaignIds:
            campaignIds.length > 0 ? campaignIds.map(Number) : undefined,
          statuses:
            statusFilters.length > 0 ? statusFilters.map(Number) : undefined,
          startDate: fromDate
            ? dayjs(fromDate).format('YYYY-MM-DD')
            : undefined,
          endDate: toDate ? dayjs(toDate).format('YYYY-MM-DD') : undefined,
          minCartValue: Number(minCartValue),
          maxCartValue: Number(maxCartValue),
          userPhoneNumber: userPhoneNumber,
        },
        params: {
          skip: 0,
          limit: 100,
        },
      };

      const response = await getTasks()(data);

      setTasks(response.data.data.tasks);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [
    campaignIds,
    statusFilters,
    fromDate,
    toDate,
    minCartValue,
    maxCartValue,
    userPhoneNumber,
  ]);

  const handleRowClick = (params) => {
    const userId = params.row.userId;
    const taskId = params.row.id;
    navigate(`/crm-dashboard/lead-history/${userId}/${taskId}`);
  };

  const rows = tasks.map((task) => ({
    id: task.id,
    name: task.name,
    campaignId: task.campaignId || 'N/A',
    phoneNumber: task?.user?.phoneNumber || 'N/A',
    userId: task.userId || 'N/A',
    activeCartValue: Number(task.activeCartValue),
    status: statusOptions[task.status] || 'N/A',
    statusCode: task.status,
    createdAt: new Date(task.createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    agentName: task.agent?.username || '-',
  }));

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Task Name', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 120 },
    { field: 'userId', headerName: 'User ID', width: 120 },
    { field: 'activeCartValue', headerName: 'Active Cart Value', width: 160 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'createdAt', headerName: 'Created At', width: 180 },
    { field: 'agentName', headerName: 'Agent Name', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => {
        if (params.row.statusCode === 5) {
          return (
            <IconButton
              color='primary'
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click
                handleAcceptTask(params.row.id, 10);
              }}
              style={{
                padding: '4px 8px',
                borderRadius: '20px',
                border: '1px solid #1976d2',
                color: '#1976d2',
                fontSize: '12px',
              }}
            >
              <AssignmentTurnedIn />
              &nbsp;Accept Task
            </IconButton>
          );
        } else if (params.row.statusCode === 10) {
          return (
            <IconButton
              color='secondary'
              onClick={(e) => {
                e.stopPropagation();
                handleConfirmStatusChange(params.row.id, params.row.userId);
              }}
              style={{
                padding: '4px 8px',
                borderRadius: '20px',
                border: '1px solid #4caf50',
                color: '#4caf50',
                fontSize: '12px',
              }}
            >
              <CheckCircle />
              &nbsp;Mark Complete
            </IconButton>
          );
        }
        return '-';
      },
    },
  ];

  return (
    <>
      {loading || campaignOptions.length === 0 ? (
        <PageLoader />
      ) : (
        <div className='home'>
          <div
            className={styles.filtersContainer}
            style={{ display: 'flex', gap: '10px' }}
          >
            <FormControl variant='outlined' fullWidth>
              <Autocomplete
                id='campaign-autocomplete'
                multiple
                options={campaignOptions.map((campaign) => campaign.name)}
                value={
                  campaignIds.map(
                    (id) =>
                      campaignOptions.find((campaign) => campaign.id === id)
                        ?.name
                  ) || []
                }
                onChange={(event, newValue) => {
                  const selectedCampaignIds = newValue
                    .map((name) => {
                      const selectedCampaign = campaignOptions.find(
                        (campaign) => campaign.name === name
                      );
                      return selectedCampaign ? selectedCampaign.id : null;
                    })
                    .filter((id) => id !== null);
                  setCampaignIds(selectedCampaignIds);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Campaign' variant='outlined' />
                )}
              />
            </FormControl>
            <FormControl variant='outlined' fullWidth>
              <Autocomplete
                id='status-autocomplete'
                multiple
                options={Object.values(statusOptions)}
                value={
                  statusFilters.map((status) => statusOptions[status]) || []
                }
                onChange={(event, newValue) => {
                  const selectedStatusIds = newValue
                    .map((name) => {
                      const selectedStatus = Object.keys(statusOptions).find(
                        (key) => statusOptions[key] === name
                      );
                      return selectedStatus ? parseInt(selectedStatus) : null;
                    })
                    .filter((id) => id !== null);
                  setStatusFilters(selectedStatusIds);
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Status' variant='outlined' />
                )}
              />
            </FormControl>
            <FormControl variant='outlined' fullWidth>
              <TextField
                label='User Phone Number'
                value={userPhoneNumber}
                onChange={(e) => setUserPhoneNumber(e.target.value)}
              />
            </FormControl>
          </div>

          <div
            className={styles.filtersContainer}
            style={{ display: 'flex', gap: '10px' }}
          >
            <TextField
              fullWidth
              type='number'
              label='Min Value'
              value={minCartValue || ''}
              onChange={(e) => setMinCartValue(Number(e.target.value))}
            />
            <TextField
              fullWidth
              type='number'
              label='Max Value'
              value={maxCartValue || ''}
              onChange={(e) => setMaxCartValue(Number(e.target.value))}
            />
            <Grid fullWidth>
              <CustomDateRangePicker
                setStartDate={setFromDate}
                setEndDate={setToDate}
                startDate={fromDate}
                endDate={toDate}
              />
            </Grid>
          </div>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            loading={loading}
            autoHeight
            disableSelectionOnClick
            onRowClick={handleRowClick}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
              },
              '& .MuiDataGrid-footerContainer': {
                backgroundColor: '#f5f5f5',
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default CRMTasksPage;
