import React from 'react';
import styles from '../pickSKU.module.scss';
import ScanBarCode from 'components/ScanBarCode';
import { scanBoxCode } from 'utils/api';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

const Header = ({
  isVerified,
  setIsVerified,
  boxCode,
  rackCode,
  orderId,
  navigation = true,
}) => {
  const navigate = useNavigate();

  const handleScanBoxCode = async (code) => {
    try {
      if (!isVerified) {
        if (code == boxCode) {
          setIsVerified(true);
        } else {
          enqueueSnackbar('Wrong Box', 'error');
        }
      } else {
        const data = {
          orderId: Number(orderId),
          barcodeSerial: code,
        };
        await scanBoxCode()(data);
        navigation && navigate(`/wms/picker/pickSKU/${orderId}/${code}`);
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Error changing Box code', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  return (
    <div className={styles.header}>
      <div>
        <p>Box Code: {boxCode}</p>
        <p>Rack Code: {rackCode}</p>
        <p>Order Id: {orderId}</p>
      </div>
      <ScanBarCode
        text={isVerified ? 'Change Box Code' : 'Verify Box Code'}
        handleFunction={handleScanBoxCode}
      />
    </div>
  );
};

export default Header;
