import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import styles from './leadHistory.module.scss';
import { eventConfigs } from './utils';

const generateTable = (rows) => (
  <Table size='small'>
    <TableBody>
      {rows.map(
        ({ label, value, onClick, customStyle }) =>
          value && (
            <TableRow key={label}>
              <TableCell component='th' scope='row'>
                <p className={`${styles.tableHeader}`}>{label}</p>
              </TableCell>
              <TableCell>
                <p
                  onClick={() => onClick()}
                  className={`${styles.tableHeader} ${styles[customStyle]}`}
                >
                  {value}
                </p>
              </TableCell>
            </TableRow>
          )
      )}
    </TableBody>
  </Table>
);

export const renderSpecificEventContent = (event) => {
  const config = eventConfigs[event?.eventName];
  if (!config) return null;

  const { content, rows } = config(event);
  const eventClassName =
    event?.eventName?.replace(/\s+/g, '').toLowerCase() + 'Event';

  return (
    <div className={styles[eventClassName]}>
      {content}
      {rows.length > 0 && generateTable(rows)}
    </div>
  );
};
