import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { enqueueSnackbar, SnackbarProvider, closeSnackbar } from 'notistack';

import { useAuth } from './context/authContext';
import './App.css';
import Layout from 'containers/Layout/Layout';
import PageLoader from 'components/Loaders/PageLoader';
import PendingOrderListingPage from './pages/Procurement Dashboard/PendingOrder/PendingOrderListing';
import DraftPOView from './pages/Procurement Dashboard/DraftPOView';
import PurchaseOrders from './pages/Procurement Dashboard/Purchase Orders';
import POItemLevel from './pages/Procurement Dashboard/P.O. Item Level';
import ReOrderPoints from './pages/Procurement Dashboard/ReOrderPoints';
import ReOrderPointsSummary from './pages/Procurement Dashboard/ReOrderPointsSummary';
import QCListing from './pages/QC Dashboard/QCListing';
import QCDetails from './pages/QC Dashboard/QCDetails';
import QCSplitDetails from './pages/QC Dashboard/QCDetailsSplitOrders';
import CRMLeadHistory from './pages/CRM Dashboard/LeadHistory';
import CRMTasksPage from './pages/CRM Dashboard/Tasks';
import CRMTasksPageAdmin from './pages/CRM Dashboard/TasksAdmin';
import DefaultHome from './pages/DefaultHome/DefaultHome';
import AcceptOrder from './pages/WMS Picker/Accept Order';
import ScanCode from './pages/WMS Picker/ScanCode';
import PickSKU from './pages/WMS Picker/Pick SKU';
import OrderListTL from './pages/WMS Picker/OrderListTL';
import IssueOrderDetails from './pages/WMS Picker/IssueOrderDetails';
import AllDispositions from './pages/CRM Dashboard/AllDispositions';

const ListingPage = lazy(() =>
  import('./pages/Doctor And Pharmacist/ListingPage/ListingPage')
);
const Login = lazy(() => import('./pages/Login/Login'));
const Sidebar = lazy(() => import('containers/Layout/Sidebar/Sidebar'));
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'));
const Manage = lazy(() => import('./pages/Manage/Manage'));
const OrderPage = lazy(() =>
  import('./pages/Doctor And Pharmacist/OrderPage/OrderPage')
);

const ProcuremetItemListingPage = lazy(() =>
  import('./pages/Procurement Dashboard/Procurement/ProcurementListing')
);

function App() {
  const { login, setLogin, setProfile, setPermissions, permissions } =
    useAuth();
  const id = JSON.parse(
    localStorage.getItem('platinumRxDashboardUserDetail')
  )?.id;
  const isProduction = window.location.hostname.includes('platinumrx.in');
  const baseURL = isProduction
    ? 'https://cron.platinumrx.in'
    : 'https://backendstaging.platinumrx.in';
  const auth = localStorage.getItem('platinumRxDashboardToken');

  useEffect(() => {
    if (!auth) setLogin(false);
    let detail = JSON.parse(
      localStorage.getItem('platinumRxDashboardUserDetail')
    );
    if (detail) {
      setProfile(detail);
      let roles = [];
      detail.roles.map((role) => {
        roles = [...roles, ...role.permissions];
        roles.push(role.name);
      });
      setPermissions(roles);
    }
  }, []);

  const handleNotificationClick = (message) => {
    window.open(message, '_blank');
  };

  useEffect(() => {
    if (id) {
      let eventSource;
      const connectSSE = () => {
        eventSource = new EventSource(
          `${baseURL}/webNotifications/subscribe/${id}`
        );

        eventSource.onopen = () => {
          console.log('SSE connection established');
        };

        eventSource.onmessage = (event) => {
          console.log('New message:', event.data);
          const data = JSON.parse(event.data);

          // Using Snackbar for notifications
          enqueueSnackbar(data.message, {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            autoHideDuration: 5000,
            action: (key) => (
              <button
                onClick={() => {
                  handleNotificationClick(data.message);
                  // Close the snackbar
                  closeSnackbar(key);
                }}
                style={{
                  color: '#fff',
                  background: 'none',
                  border: 'none',
                  padding: '6px 10px',
                  cursor: 'pointer',
                  fontSize: '14px',
                }}
              >
                View
              </button>
            ),
          });
        };
      };
      connectSSE();

      return () => {
        if (eventSource) {
          eventSource.close();
        }
      };
    }
  }, [id, baseURL]);

  const hasMultiplePermissions = () => {
    const relevantPermissions = [
      'FETCH_PHARMACIST_ORDERS',
      'PHARMACIST',
      'DOCTOR',
      'ASSIGN_DOCTOR',
      'QC_STATUS_UPDATE',
      'FETCH_WAREHOUSE_PENDING_ORDERS',
      'FETCH_ALL_TASKS',
      'FETCH_TASKS',
    ];
    return (
      relevantPermissions.filter((perm) => permissions.includes(perm)).length >
      1
    );
  };

  const getHomeComponent = () => {
    if (hasMultiplePermissions()) {
      return <DefaultHome />;
    }
    if (
      permissions.includes('FETCH_PHARMACIST_ORDERS') ||
      permissions.includes('PHARMACIST') ||
      permissions.includes('DOCTOR') ||
      permissions.includes('ASSIGN_DOCTOR')
    ) {
      return <ListingPage />;
    }
    if (permissions.includes('QC_STATUS_UPDATE')) {
      return <QCListing />;
    }
    if (permissions.includes('FETCH_WAREHOUSE_PENDING_ORDERS')) {
      return <PendingOrderListingPage />;
    }
    if (
      permissions.includes('FETCH_ALL_TASKS') ||
      permissions.includes('FETCH_TASKS')
    ) {
      return permissions.includes('FETCH_ALL_TASKS') ? (
        <CRMTasksPageAdmin />
      ) : (
        <CRMTasksPage />
      );
    }
    return <DefaultHome />; // fallback
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      dense
      preventDuplicate
    >
      <div className='App'>
        <BrowserRouter>
          <Suspense fallback={<PageLoader />}>
            {!login ? (
              <Login />
            ) : (
              <>
                <Sidebar />
                <Routes>
                  <Route
                    path='/'
                    element={
                      <Layout title='Summary'>{getHomeComponent()}</Layout>
                    }
                  />
                  <Route
                    path='/patients'
                    element={
                      <Layout title='Pharmacist/Doctor Dashboard'>
                        <ListingPage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/qc-order/:orderId/:statusId'
                    element={
                      <Layout title=''>
                        <QCDetails />
                      </Layout>
                    }
                  />
                  <Route
                    path='/qc-split-order/:orderId'
                    element={
                      <Layout title=''>
                        <QCSplitDetails />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/items'
                    element={
                      <Layout title='Summary'>
                        <ProcuremetItemListingPage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/DraftPOView'
                    element={
                      <Layout title='Summary'>
                        <DraftPOView />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/pending-orders'
                    element={
                      <Layout title='Summary'>
                        <PendingOrderListingPage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/purchase-orders-item-level'
                    element={
                      <Layout title='Summary'>
                        <POItemLevel />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/purchase-orders'
                    element={
                      <Layout title='Summary'>
                        <PurchaseOrders />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/reorder-points'
                    element={
                      <Layout title='Summary'>
                        <ReOrderPoints />
                      </Layout>
                    }
                  />
                  <Route
                    path='/procurement-dashboard/reorder-points-summary'
                    element={
                      <Layout title='Summary'>
                        <ReOrderPointsSummary />
                      </Layout>
                    }
                  />
                  <Route
                    path='/order/:orderId/:statusId'
                    element={
                      <Layout title=''>
                        <OrderPage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/qc-listing'
                    element={
                      <Layout title='QCListing'>
                        <QCListing />
                      </Layout>
                    }
                  />
                  <Route
                    path='/manage'
                    element={
                      <Layout title='Manage'>
                        <Manage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/profile'
                    element={
                      <Layout title='Profile'>
                        <ProfilePage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/crm-dashboard/lead-history/:userId/:taskId'
                    element={
                      <Layout title='Lead History'>
                        <CRMLeadHistory />
                      </Layout>
                    }
                  />
                  <Route
                    path='/crm-dashboard/tasks'
                    element={
                      <Layout title='Tasks'>
                        <CRMTasksPage />
                      </Layout>
                    }
                  />
                  <Route
                    path='/crm-dashboard/tasks-admin'
                    element={
                      <Layout title='Tasks'>
                        <CRMTasksPageAdmin />
                      </Layout>
                    }
                  />
                  <Route
                    path='/crm-dashboard/all-dispositions'
                    element={
                      <Layout title='All Dispositions'>
                        <AllDispositions />
                      </Layout>
                    }
                  />
                  <Route
                    path='/wms/picker/acceptOrder'
                    element={
                      <Layout title='Picker'>
                        <AcceptOrder />
                      </Layout>
                    }
                  />
                  <Route
                    path='/wms/picker/scanCode/:orderId'
                    element={
                      <Layout title='Picker'>
                        <ScanCode />
                      </Layout>
                    }
                  />
                  <Route
                    path='/wms/picker/pickSKU/:orderId/:boxCode'
                    element={
                      <Layout title='Picker'>
                        <PickSKU />
                      </Layout>
                    }
                  />
                  <Route
                    path='/wms/picker/tl/order-listing'
                    element={
                      <Layout title='Picker'>
                        <OrderListTL />
                      </Layout>
                    }
                  />
                  <Route
                    path='/wms/picker/tl/issue-order-details/:orderId'
                    element={
                      <Layout title='Picker'>
                        <IssueOrderDetails />
                      </Layout>
                    }
                  />
                </Routes>
              </>
            )}
          </Suspense>
        </BrowserRouter>
      </div>
    </SnackbarProvider>
  );
}

export default App;
