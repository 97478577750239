import {
  getFormattedDate,
  getTimestamp,
  handlePdpRedirect,
} from 'utils/commonFunctions';
import {
  Timeline,
  Search,
  ShoppingCart,
  Delete,
  Add,
  Edit,
  Logout,
  Login,
  AddShoppingCart,
  RemoveShoppingCart,
  AddBox,
  RemoveBox,
  Phone,
  Chat,
} from '@mui/icons-material';

export const eventConfigs = {
  'SEARCHED PRODUCT': (event) => ({
    content: <span>Searched for {event?.eventData?.request?.drugName}</span>,
    rows: [
      { label: 'Search Query', value: event?.eventData?.request?.drugName },
      { label: 'Search Timestamp', value: getFormattedDate(event?.createdAt) },
      {
        label: 'Medicine Found',
        value: event?.eventData?.total > 0 ? 'Yes' : 'No',
      },
      {
        label: 'Substitute Available',
        value: event?.eventData?.countWithSubstitute > 0 ? 'Yes' : 'No',
      },
      { label: 'No of Results', value: event?.eventData?.total },
      {
        label: 'No of Results with Substitutes',
        value: event?.eventData?.countWithSubstitute,
      },
    ],
  }),
  'VIEWED CART': () => ({
    content: <span>Viewed Cart</span>,
    rows: [],
  }),
  'ADDED ITEM TO CART': (event) => ({
    content: <span>Added To Cart</span>,
    rows: [
      { label: 'SKU Name', value: event?.eventData?.SKU_Name },
      { label: 'SKU ID', value: event?.eventData?.SKU_ID },
      { label: 'SKU MRP', value: event?.eventData?.SKU_MRP },
      {
        label: 'SKU Discounted Price',
        value: event?.eventData?.SKU_Discounted_Price,
      },
      { label: 'SKU Quantity', value: event?.eventData?.SKU_Quantity },
      {
        label: 'Time of Item Addition',
        value: event?.eventData?.Time_of_Item_Addition,
      },
      { label: 'Source', value: event?.eventData?.Source },
    ],
  }),

  'DELETED ITEM FROM CART': (event) => ({
    content: <span>Deleted Item From Cart</span>,
    rows: [
      { label: 'Notes', value: 'Deleted From Cart' },
      { label: 'Performed By', value: 'Customer' },
      {
        label: 'SKU ID',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugCode,
      },
      {
        label: 'SKU Name',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugName,
        onClick: () => {
          handlePdpRedirect(
            event?.eventData?.itemData?.[0]?.itemCartData?.drugName,
            event?.eventData?.itemData?.[0]?.itemCartData?.drugCode
          );
        },
        customStyle: 'link',
      },
      {
        label: 'SKU MRP',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.unitMrp,
      },
      {
        label: 'SKU Discounted Price',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.unitDiscountedMrp,
      },
      {
        label: 'SKU Quantity',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.quantity,
      },
      {
        label: 'Time of Item Addition',
        value: getFormattedDate(
          event?.eventData?.itemData?.[0]?.itemCartData?.createdAt
        ),
      },
      { label: 'Source', value: event?.source },
    ],
  }),

  'ADDED ITEM TO CART': (event) => ({
    content: <span>Added To Cart</span>,
    rows: [
      { label: 'Notes', value: 'Added To Cart' },
      { label: 'Performed By', value: 'Customer' },
      {
        label: 'SKU ID',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugCode,
      },
      {
        label: 'SKU Name',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugName,
        onClick: () => {
          handlePdpRedirect(
            event?.eventData?.itemData?.[0]?.itemCartData?.drugName,
            event?.eventData?.itemData?.[0]?.itemCartData?.drugCode
          );
        },
        customStyle: 'link',
      },
      {
        label: 'SKU MRP',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.unitMrp,
      },
      {
        label: 'SKU Discounted Price',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.unitDiscountedMrp,
      },
      {
        label: 'SKU Quantity',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.quantity,
      },
      {
        label: 'Time of Item Addition',
        value: getFormattedDate(
          event?.eventData?.itemData?.[0]?.itemCartData?.createdAt
        ),
      },
      { label: 'Source', value: event?.source },
    ],
  }),
  'SET ITEM TO CART': (event) => ({
    content: <span>Set Item To Cart</span>,
    rows: [
      { label: 'Notes', value: 'Set Item To Cart' },
      { label: 'Performed By', value: 'Customer' },
      {
        label: 'SKU ID',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugCode,
      },
      {
        label: 'SKU Name',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugName,
        onClick: () => {
          handlePdpRedirect(
            event?.eventData?.itemData?.[0]?.itemCartData?.drugName,
            event?.eventData?.itemData?.[0]?.itemCartData?.drugCode
          );
        },
        customStyle: 'link',
      },
      {
        label: 'SKU MRP',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.unitMrp,
      },
      {
        label: 'SKU Discounted Price',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.unitDiscountedMrp,
      },
      {
        label: 'SKU Quantity',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.quantity,
      },
      {
        label: 'Time of Item Addition',
        value: getFormattedDate(
          event?.eventData?.itemData?.[0]?.itemCartData?.createdAt
        ),
      },
    ],
  }),
  'CUSTOMER LOGGED IN': (event) => ({
    content: <span>Customer Logged In</span>,
    rows: [
      { label: 'Notes', value: 'Customer Logged In' },
      { label: 'Status', value: event?.eventData?.userStatus || 'Active' },
      { label: 'Phone Number', value: event?.eventData?.request?.phone_number },
      { label: 'OTP', value: event?.eventData?.request?.otp_code },
      {
        label: 'Login Time',
        value: getFormattedDate(event?.createdAt),
      },
      { label: 'Source', value: event?.eventData?.source || 'Unknown Source' },
    ],
  }),
  'WHATSAPP CHAT INITIATED': (event) => ({
    content: <span>Whatsapp Chat Initiated</span>,
    rows: [
      { label: 'Notes', value: 'Whatsapp Message BE' },
      { label: 'Phone Number', value: event?.eventData?.request?.phoneNumber },
      { label: 'Status', value: event?.userStatus || 'Active' },
      { label: 'Owner', value: event?.source || 'Unknown Source' },
    ],
  }),
  'LOGGED IN': (event) => ({
    content: <span>Customer Logged In</span>,
    rows: [
      { label: 'Owner', value: event?.source || 'Unknown Source' },
      { label: 'Notes', value: 'Customer Logged In' },
      { label: 'Phone Number', value: event?.eventData?.request?.phone_number },
      { label: 'OTP', value: event?.eventData?.request?.otp_code },
      {
        label: 'Login Time',
        value: new Date(event?.createdAt).toLocaleString('en-IN') || 'N/A',
      },
      { label: 'Source', value: event?.source || 'Unknown Source' },
      { label: 'Status', value: event?.userStatus || 'Active' },
    ],
  }),
  'ORDER PLACED': (event) => ({
    content: <span>Order Placed</span>,
    rows: [
      { label: 'Owner', value: event?.source || 'N/A' },
      { label: 'Status', value: 'Active' },
      { label: 'Notes', value: '' },
      {
        label: 'Payment Option',
        value: event?.eventData?.request?.paymentType || 'Unknown',
      },
      {
        label: 'Order Value',
        value: event?.eventData?.priceDetails?.totalAmountToBePaid || 'N/A',
      },
      {
        label: 'Customer Phone Number',
        value: event?.eventData?.request?.patientMobileNumber || 'N/A',
      },
      {
        label: 'City',
        value: event?.eventData?.request?.city || 'Unknown',
      },
      {
        label: 'Delivery Phone Number',
        value: event?.eventData?.request?.patientMobileNumber || 'N/A',
      },
      { label: 'Source', value: event?.source || 'Unknown' },
    ],
  }),
  'CLICKED ON CALL TO ORDER': (event) => ({
    content: <span>Reach Out To Us (If Medicine Not Found)</span>,
    rows: [
      { label: 'Notes', value: 'Reach Out Us (If Medicine Not Found)' },
      { label: 'Phone Number', value: event?.eventData?.request?.phoneNumber },
      {
        label: 'Timestamp',
        value: new Date(event?.createdAt).toLocaleString(),
      },
      { label: 'Status', value: event?.userStatus || 'Active' },
      { label: 'Owner', value: event?.source || 'Unknown Source' },
    ],
  }),
  'UPLOADED RX': (event) => ({
    content: <span>Prescription Uploaded</span>,
    rows: [
      { label: 'Notes', value: 'UPLOADED RX' },
      {
        label: 'First Message Timestamp',
        value: getFormattedDate(event?.createdAt),
      },
      { label: 'Status', value: event?.userStatus || 'Active' },
      { label: 'Owner', value: event?.source || 'Unknown Source' },
    ],
  }),
  FOLLOW_UP: (event) => ({
    content: <span>Follow Up</span>,
    rows: [
      { label: 'Status', value: event?.eventData?.request?.status || 'N/A' },
      {
        label: 'Disposition',
        value: event?.eventData?.request?.disposition || 'N/A',
      },
      {
        label: 'Sub Disposition',
        value: event?.eventData?.request?.subDisposition || 'N/A',
      },
      {
        label: 'Follow Up Date',
        value: getTimestamp(event?.eventData?.followUpDate),
      },
      {
        label: 'Notes',
        value: event?.eventData?.request?.meta?.notes || 'N/A',
      },
      {
        label: 'Medicine Remaining',
        value: event?.eventData?.request?.meta?.medicineRemaining || 'N/A',
      },
      { label: 'Source', value: event?.source || 'Unknown' },
    ],
  }),
  'INTERNAL USER ADDED ITEM TO CART': (event) => ({
    content: <span>Internal User Added Item To Cart</span>,
    rows: [
      {
        label: 'Drug Name',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugName,
      },
      {
        label: 'Drug Code',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugCode,
      },
      {
        label: 'Unit MRP',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.unitMrp,
      },
      {
        label: 'Quantity',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.quantity,
      },
      {
        label: 'Total Price',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.itemTotalPrice,
      },
      {
        label: 'Discounted Price',
        value:
          event?.eventData?.itemData?.[0]?.itemCartData?.itemTotalDiscountPrice,
      },
      {
        label: 'Manufacturer',
        value:
          event?.eventData?.itemData?.[0]?.itemCatalogData?.manufacturerName,
      },
      { label: 'Added At', value: getTimestamp(event?.createdAt) },
      {
        label: 'Added By',
        value: `Internal User ${event?.eventData?.request?.internal_user_id}`,
      },
    ],
  }),
  'INTERNAL SET ITEM TO CART': (event) => ({
    content: <span>Internal User Set Item To Cart</span>,
    rows: [
      {
        label: 'Drug Name',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugName,
      },
      {
        label: 'Drug Code',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.drugCode,
      },
      {
        label: 'Unit MRP',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.unitMrp,
      },
      {
        label: 'Quantity',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.quantity,
      },
      {
        label: 'Total Price',
        value: event?.eventData?.itemData?.[0]?.itemCartData?.itemTotalPrice,
      },
      {
        label: 'Discounted Price',
        value:
          event?.eventData?.itemData?.[0]?.itemCartData?.itemTotalDiscountPrice,
      },
      {
        label: 'Manufacturer',
        value:
          event?.eventData?.itemData?.[0]?.itemCatalogData?.manufacturerName,
      },
      { label: 'Added At', value: getTimestamp(event?.createdAt) },
      {
        label: 'Added By',
        value: `Internal User ${event?.eventData?.request?.internal_user_id}`,
      },
      {
        label: 'Increased Quantity By',
        value: event?.eventData?.request?.increaseQuantityBy,
      },
    ],
  }),
  DISPOSED_TASK: (event) => ({
    content: <span>Task Disposed</span>,
    rows: [
      { label: 'Status', value: event?.eventData?.request?.status },
      { label: 'Disposition', value: event?.eventData?.request?.disposition },
      {
        label: 'Sub Disposition',
        value: event?.eventData?.request?.subDisposition,
      },
      { label: 'Task ID', value: event?.eventData?.request?.taskId },
      {
        label: 'Internal User ID',
        value: event?.eventData?.request?.internalUserId,
      },
      {
        label: 'Follow Up Date',
        value: getTimestamp(event?.eventData?.request?.followUpDate),
      },
      {
        label: 'Task Completed',
        value: event?.eventData?.request?.completeTask ? 'Yes' : 'No',
      },
      { label: 'Disposed At', value: getTimestamp(event?.createdAt) },
      { label: 'User Status', value: event?.userStatus },
    ],
  }),
  REMIND_REPEAT_ORDER: (event) => ({
    content: <span>Remind Repeat Order</span>,
    rows: [
      { label: 'Order ID', value: event?.eventData?.order?.orderId },
      { label: 'User ID', value: event?.eventData?.order?.userId },
      { label: 'Order Status', value: event?.eventData?.order?.statusId },
      {
        label: 'Order Remarks',
        value: event?.eventData?.order?.orderRemarks || 'N/A',
      },
      {
        label: 'Created At',
        value: getTimestamp(event?.eventData?.order?.createdAt),
      },
      {
        label: 'Follow Up Date',
        value: getTimestamp(event?.eventData?.followUpDate),
      },
      {
        label: 'Order Creation Flow',
        value: event?.eventData?.order?.orderCreationFlow,
      },
      {
        label: 'Fulfillment Date',
        value: event?.eventData?.order?.orderFulfillmentDate,
      },
      { label: 'Source', value: event?.source || 'Unknown' },
      { label: 'User Status', value: event?.userStatus },
    ],
  }),
  'COD ORDER PLACED': (event) => ({
    content: <span>COD Order Placed</span>,
    rows: [
      { label: 'Order ID', value: event?.eventData?.orderId },
      { label: 'User Name', value: event?.eventData?.request?.userName },
      { label: 'Patient Name', value: event?.eventData?.request?.patientName },
      { label: 'Patient Age', value: event?.eventData?.request?.patientAge },
      {
        label: 'Patient Gender',
        value: event?.eventData?.request?.patientGender,
      },
      {
        label: 'Mobile Number',
        value: event?.eventData?.request?.patientMobileNumber,
      },
      { label: 'Address', value: event?.eventData?.request?.patientAddress },
      { label: 'Payment Type', value: event?.eventData?.request?.paymentType },
      { label: 'Order Type', value: event?.eventData?.request?.orderType },
      { label: 'Total MRP', value: event?.eventData?.priceDetails?.totalMrp },
      {
        label: 'Total Discount',
        value: event?.eventData?.priceDetails?.totalDiscount,
      },
      {
        label: 'Shipping Charges',
        value: event?.eventData?.priceDetails?.shippingCharges,
      },
      {
        label: 'Total Amount',
        value: event?.eventData?.priceDetails?.totalAmountToBePaid,
      },
      { label: 'Created At', value: getTimestamp(event?.createdAt) },
      { label: 'Source', value: event?.source },
      { label: 'User Status', value: event?.userStatus },
    ],
  }),
  'ONLINE PAYMENT SUCCESS': (event) => ({
    content: <span>Online Payment Success</span>,
    rows: [
      { label: 'Order ID', value: event?.eventData?.orderId },
      { label: 'Payment Method', value: event?.eventData?.paymentMethod },
      {
        label: 'Transaction Status',
        value: event?.eventData?.transactionStatus,
      },
      { label: 'Total MRP', value: event?.eventData?.priceDetails?.totalMrp },
      {
        label: 'Total Discount',
        value: event?.eventData?.priceDetails?.totalDiscount,
      },
      {
        label: 'Shipping Charges',
        value: event?.eventData?.priceDetails?.shippingCharges,
      },
      {
        label: 'Total Amount Paid',
        value: event?.eventData?.priceDetails?.totalAmountToBePaid,
      },
      {
        label: 'Transaction ID',
        value: event?.eventData?.transactionData?.providerReferenceId,
      },
      {
        label: 'Payment Gateway',
        value: event?.eventData?.transactionData?.paymentGateway,
      },
      { label: 'Created At', value: getTimestamp(event?.createdAt) },
      { label: 'Source', value: event?.source },
      { label: 'User Status', value: event?.userStatus },
    ],
  }),

  'PAYMENT CHECKOUT INITIATED': (event) => ({
    content: <span>Payment Checkout Initiated</span>,
    rows: [
      { label: 'User Name', value: event?.eventData?.request?.userName },
      { label: 'Patient Name', value: event?.eventData?.request?.patientName },
      { label: 'Patient Age', value: event?.eventData?.request?.patientAge },
      {
        label: 'Patient Gender',
        value: event?.eventData?.request?.patientGender,
      },
      {
        label: 'Mobile Number',
        value: event?.eventData?.request?.patientMobileNumber,
      },
      { label: 'Address', value: event?.eventData?.request?.patientAddress },
      { label: 'Payment Type', value: event?.eventData?.request?.paymentType },
      { label: 'Order Type', value: event?.eventData?.request?.orderType },
      { label: 'Created At', value: getTimestamp(event?.createdAt) },
      { label: 'Source', value: event?.source },
      { label: 'User Status', value: event?.userStatus },
    ],
  }),

  'WHATSAPP MESSAGE SALES': (event) => ({
    content: <span>Whatsapp Message From Sales</span>,
    rows: [
      { label: 'Customer Name', value: event?.eventData?.request?.name },
      { label: 'Message', value: event?.eventData?.request?.text },
      { label: 'Phone Number', value: event?.eventData?.phoneNumber },
      { label: 'Message Type', value: event?.eventData?.messageType },
      { label: 'Whatsapp Number', value: event?.eventData?.request?.waNumber },
      { label: 'Status', value: event?.userStatus || 'Not Logged In' },
      { label: 'Timestamp', value: getFormattedDate(event?.createdAt) },
    ],
  }),
};

export const getEventIcon = (eventType) => {
  const icons = {
    'SEARCHED PRODUCT': Search,
    'VIEWED CART': ShoppingCart,
    'ADDED ITEM TO CART': AddShoppingCart,
    'DELETED ITEM FROM CART': RemoveShoppingCart,
    'UPDATED ITEM IN CART': Edit,
    'LOGGED IN': Login,
    'ORDER PLACED': ShoppingCart,
    'CLICKED ON CALL TO ORDER': Phone,
    'UPLOADED RX': AddBox,
    'WHATSAPP CHAT INITIATED': Chat,
    'CUSTOMER LOGGED IN': Login,
    'SET ITEM TO CART': AddBox,
    'UPDATED ITEM IN CART': Edit,
    'UPDATED ITEM IN CART': Edit,
    default: Timeline, // Added default icon explicitly
  };
  const Icon = icons[eventType] || icons.default;
  return <Icon sx={{ fontSize: '16px', color: '#fff' }} />;
};
