import React, { useEffect, useRef, useState } from 'react';
import styles from './pickSKU.module.scss';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CartDetails from 'containers/QCDetails/CartDetails';
import { useNavigate } from 'react-router-dom';
import {
  raisePickerIssues,
  updateIssueAPI,
  deleteIssueAPI,
  unassignItem,
} from 'utils/api';
import { enqueueSnackbar } from 'notistack';
import ScanBarCode from 'components/ScanBarCode';
import Button from 'components/CustomElements/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import 'components/swiper/navigation.css';
import {
  Image,
  AcUnit,
  Close,
  Delete,
  CheckCircle,
  Cancel,
  Circle,
} from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OrImg from 'assets/icons/or.png';
import dayjs from 'dayjs';
import Header from './Header';

const PickSKUIndex = ({
  tl,
  orderId,
  boxCode,
  getDetails,
  details,
  setDetails,
  savedRemarks,
  setBatchRows,
  remarks,
  setRemarks,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [issueReason, setIssueReason] = useState('');
  const swiperRef = useRef(null); // Ref to store the Swiper instance
  const [showScreen, setShowScreen] = useState('picking');
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [rackCode, setRackCode] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      details?.orderItems?.[currentSlide]?.itemCatalogData?.warehouseLocations
    ) {
      const currentItem = details.orderItems[currentSlide];
      const locations = currentItem.itemCatalogData.warehouseLocations;

      setBatchRows(
        locations.map((loc, index) => ({
          id: index,
          batch: loc.batch,
          availableQty: loc.quantity,
          pickedQty: 0,
          mrp: currentItem.itemCartData.unitMrp,
          expiry: dayjs(loc.expiresOn).format('YYYY-MM-DD'),
        }))
      );
    }
  }, [currentSlide, details]);

  const handleNextSlide = () => {
    if (swiperRef.current) {
      const isLastSlide = currentSlide === details?.orderItems?.length - 1;

      if (isLastSlide) {
        const hasIssues = Object.keys(remarks).length > 0;
        setShowScreen(hasIssues ? 'Issues Confirmation' : 'No Issue');
      } else {
        setCurrentSlide((prev) =>
          Math.min(prev + 1, details.orderItems.length - 1)
        );
        swiperRef.current.slideNext();
      }
    }
  };

  const handleSkipSlide = () => {
    // Check if it's the last SKU
    if (currentSlide === details?.orderItems.length - 1) {
      // Navigate to confirmation page if it's the last SKU
      setShowScreen('Issues Confirmation');
    } else {
      // Simply slide to the next SKU without doing anything
      if (swiperRef.current) {
        swiperRef.current.slideNext();
      }
    }
  };

  const handleSKUAction = async (action) => {
    const currentItem = details?.orderItems[currentSlide];
    if (!currentItem) return;

    switch (action) {
      case 'pick':
        // Check if there are saved remarks for the current SKU
        const masterDrugCode = currentItem.itemCartData.drugCode;
        if (savedRemarks[masterDrugCode]) {
          try {
            const data = {
              id: savedRemarks[masterDrugCode].id,
            };
            await deleteIssueAPI()(data);
            getDetails(orderId);
            enqueueSnackbar('Issue deleted successfully', {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          } catch (error) {
            console.error('Error deleting issue:', error);
            enqueueSnackbar('Error deleting issue', {
              variant: 'error',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }
        } else if (remarks[masterDrugCode]) {
          handleRemoveIssue(masterDrugCode);
        }
        markItemAsPicked(currentSlide);
        break;

      case 'skip':
        handleSkipSlide();
        break;

      case 'delete':
        handleRemoveIssue(currentItem.itemCartData.drugCode);
        break;

      default:
        break;
    }
  };

  const markItemAsPicked = (index) => {
    setDetails((prevDetails) => {
      const updatedOrderItems = [...prevDetails.orderItems];
      updatedOrderItems[index] = {
        ...updatedOrderItems[index],
        skuPicked: true, // Mark as picked
      };
      return { ...prevDetails, orderItems: updatedOrderItems };
    });
    handleNextSlide(); // Proceed to the next slide after marking as picked
  };

  const handleRaiseIssue = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setIssueReason('');
  };

  const handleSubmitIssue = async () => {
    const currentItem = details?.orderItems[currentSlide]?.itemCartData;
    if (!currentItem) return;

    const { drugCode: masterDrugCode, drugName } = currentItem;

    const existingRemark = remarks[masterDrugCode];

    if (existingRemark) {
      const existingRemarkId = existingRemark.id;
      if (existingRemarkId) {
        try {
          const data = {
            id: existingRemarkId,
            remark: issueReason,
          };
          await updateIssueAPI()(data);
          getDetails(orderId);
          enqueueSnackbar('Issue updated successfully', {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        } catch (error) {
          console.error('Error updating issue:', error);
          enqueueSnackbar('Error updating issue', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          });
        }
      }
    } else {
      // If it's a new issue, add it to remarks
      setRemarks((prevRemarks) => ({
        ...prevRemarks,
        [masterDrugCode]: { issueReason, drugName },
      }));
      enqueueSnackbar('Issue raised successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }

    handleCloseDialog();

    const isLastSlide = currentSlide === details?.orderItems?.length - 1;
    if (isLastSlide) {
      setShowScreen('Issues Confirmation');
    } else {
      handleNextSlide();
    }
  };

  const handleGoToPick = () => {
    setShowScreen('picking');
    setCurrentSlide(0); // Set current slide to first slide

    // Reset pickedQty for all SKUs
    setDetails((prevDetails) => ({
      ...prevDetails,
      orderItems: prevDetails.orderItems.map((item) => ({
        ...item,
        skuPicked: false, // Mark as picked
      })),
    }));
  };

  const handlePreviousSlide = () => {
    setCurrentSlide((prev) => Math.max(prev - 1, 0));
    swiperRef.current.slidePrev();
  };

  const handleRemoveIssue = async (masterDrugCode) => {
    const slideIndex = details?.orderItems?.findIndex(
      (item) =>
        item.itemCartData.drugCode.toString() === masterDrugCode.toString()
    );

    if (savedRemarks[masterDrugCode]) {
      try {
        const data = {
          id: savedRemarks[masterDrugCode].id,
        };
        await deleteIssueAPI()(data);
        getDetails(orderId);
        enqueueSnackbar('Issue deleted successfully', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      } catch (error) {
        console.error('Error deleting issue:', error);
        enqueueSnackbar('Error deleting issue', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    }

    setRemarks((prevRemarks) => {
      const newRemarks = { ...prevRemarks };
      delete newRemarks[masterDrugCode];

      if (Object.keys(newRemarks).length === 0) {
        setShowScreen('picking');
      }

      return newRemarks;
    });

    if (slideIndex !== -1) {
      setCurrentSlide(slideIndex);
      setShowScreen('picking');
      setTimeout(() => {
        if (swiperRef.current) {
          swiperRef.current.slideTo(slideIndex);
        }
      }, 0);
    }
  };

  const handleIssueSubmit = async () => {
    try {
      const data = {
        orderId: Number(orderId),
        rackIdentifier: rackCode,
        category: 'Picking Issue',
        remarks: Object.entries(remarks).map(
          ([masterDrugCode, { issueReason }]) => ({
            remark: issueReason,
            masterDrugCode: Number(masterDrugCode),
          })
        ),
      };

      await raisePickerIssues()(data);
      enqueueSnackbar('Rack scanned successfully', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      window.location.href = '/wms/picker/tl/order-listing';
    } catch (error) {
      console.error('Error scanning rack:', error);
      enqueueSnackbar('Error scanning rack', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleRack = (code) => {
    setRackCode(code);
  };

  const handleSubmitSuccess = async () => {
    try {
      const data = {
        orderId: orderId,
      };
      await unassignItem()(data);
      window.location.href = '/wms/picker/tl/order-listing';
    } catch (e) {
      console.log(e);
    }
  };

  const handleImageClick = () => {
    setOpenPrescriptionModal(true);
  };

  const handleClosePrescriptionModal = () => {
    setOpenPrescriptionModal(false);
  };

  return (
    <div>
      {showScreen === 'picking' ? (
        <div className={styles.pickSKU}>
          {!tl && <Header boxCode={boxCode} orderId={orderId} />}

          <div className={styles.statusIndicators}>
            {details?.orderItems?.map((item, index) => (
              <div key={index} className={styles.statusIndicator}>
                {remarks[item.itemCartData.drugCode] ? (
                  <Cancel
                    className={currentSlide === index ? styles.zoomEffect : ''}
                    style={{ color: 'red' }}
                  />
                ) : item.skuPicked ? (
                  <CheckCircle
                    className={currentSlide === index ? styles.zoomEffect : ''}
                    style={{ color: 'green' }}
                  />
                ) : (
                  <Circle
                    className={currentSlide === index ? styles.zoomEffect : ''}
                    style={{ color: 'gray' }}
                  />
                )}
              </div>
            ))}
          </div>
          {!tl && (
            <div>
              <StyledAccordion
                className={styles.accordion}
                slotProps={{ transition: { unmountOnExit: true } }}
              >
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Order Details
                </StyledAccordionSummary>
                <div className={styles.details_container}>
                  <CartDetails orderDetails={details} />
                </div>
              </StyledAccordion>
            </div>
          )}

          <div className={styles.sku_swiper}>
            <Swiper
              navigation={{
                clickable: true,
                nextEl: '#myswiper-next',
                prevEl: '#myswiper-prev',
              }}
              grabCursor={false} // Disable dragging
              slidesPerView='auto'
              allowTouchMove={false} // Prevent touch dragging
              onSwiper={(swiper) => (swiperRef.current = swiper)} // Store the Swiper instance
              onSlideChange={(swiper) => setCurrentSlide(swiper.activeIndex)}
            >
              {details?.orderItems?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className={styles.sku_slide}>
                    <p>Medicine Details</p>
                    <div className={styles.sku_slide__basic}>
                      <div className={styles.sku_slide__basic__name}>
                        <p>{item?.itemCartData?.drugName}</p>
                        <Image
                          onClick={handleImageClick}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className={styles.sku_slide__basic__qty}>
                        <p>Qty: {item?.itemCartData?.quantity}</p>
                        <div>
                          {item?.itemCartData?.coldStorage && (
                            <AcUnit sx={{ color: 'skyblue' }} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.sku_slide__basic}>
                      <div className={styles.sku_slide__basic__jit}>
                        <p>Rack Location</p>
                        <p>
                          {item?.itemCatalogData?.warehouseLocations?.map(
                            (item) => {
                              return item.shortHandLocation;
                            }
                          )}
                        </p>
                      </div>
                    </div>
                    {/* <div className={styles.sku_slide__grid}>
                      <DataGrid
                        rows={batchRows}
                        columns={getBatchColumns()}
                        pageSize={5}
                        autoHeight
                        disableSelectionOnClick
                        hideFooterPagination
                        sx={{
                          '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#f5f5f5',
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    </div> */}
                    {currentSlide !== 0 && (
                      <div className={styles.go_back}>
                        <Button
                          type='outlinedSquared'
                          handleClick={handlePreviousSlide}
                        >
                          Go Back
                        </Button>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className={styles.bottomButtons}>
            <Button type='outlinedSquared' handleClick={handleRaiseIssue}>
              Raise a Issue
            </Button>
            {remarks[
              details?.orderItems[currentSlide]?.itemCartData.drugCode
            ] && (
              <Button
                type='outlinedSquared'
                handleClick={() => handleSKUAction('skip')}
              >
                Skip
              </Button>
            )}
            <Button
              type='containedSquared'
              handleClick={() => handleSKUAction('pick')}
            >
              Pick SKU
            </Button>
          </div>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <div className={styles.dialog}>
              <DialogTitle>Raise an Issue</DialogTitle>
              <DialogContent className={styles.dialog__content}>
                <TextField
                  autoFocus
                  margin='dense'
                  label='Issue Reason'
                  type='text'
                  fullWidth
                  variant='outlined'
                  value={issueReason}
                  onChange={(e) => setIssueReason(e.target.value)}
                />
                <img src={OrImg} alt='orImage' width='180px' />

                <FormControl fullWidth variant='outlined'>
                  <InputLabel>Predefined Issues</InputLabel>
                  <Select
                    value={issueReason}
                    onChange={(e) => setIssueReason(e.target.value)}
                    label='Predefined Issues'
                  >
                    <MenuItem value=''>Select an issue</MenuItem>
                    <MenuItem value='Item Not Found'>Item Not Found</MenuItem>
                    <MenuItem value='Item Expired'>Item Expired</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button handleClick={handleCloseDialog} color='primary'>
                  Cancel
                </Button>
                <Button handleClick={() => handleSubmitIssue()} color='primary'>
                  Submit
                </Button>
              </DialogActions>
            </div>
          </Dialog>
          <Dialog
            open={openPrescriptionModal}
            onClose={handleClosePrescriptionModal}
            maxWidth='md'
            fullWidth
          >
            <DialogTitle>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>Product Image</span>
                <Close
                  onClick={handleClosePrescriptionModal}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '20px',
                }}
              >
                <img
                  src={
                    details?.orderItems[currentSlide]?.itemCatalogData
                      ?.heroImage
                  }
                  alt={
                    details?.orderItems[currentSlide]?.itemCartData?.drugName
                  }
                  style={{
                    maxWidth: '100%',
                    maxHeight: '500px',
                    objectFit: 'contain',
                  }}
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : showScreen == 'Issues Confirmation' ? (
        <div className={styles.pickSKU}>
          <div>
            <div className={styles.issue_raised}>Confirm Issues</div>
            <div className={styles.issues}>
              {Object.entries(remarks).map(
                ([masterDrugCode, { issueReason, drugName }]) => (
                  <div key={masterDrugCode} className={styles.issueItem}>
                    <div>
                      <p className={styles.drugName}>{drugName}</p>
                      <p className={styles.drugCode}>
                        DrugCode: {masterDrugCode}
                      </p>
                      <p className={styles.reason}>Reason: {issueReason}</p>
                    </div>
                    <Delete
                      onClick={() => handleRemoveIssue(masterDrugCode)}
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
          <div>
            {rackCode && (
              <p className={styles.rackCode}>
                Scanned Rack Code: <span>{rackCode}</span>
              </p>
            )}
            <ScanBarCode text='Scan Rack' handleFunction={handleRack} />
          </div>
          <div className={styles.submit_btn}>
            <Button type='outlinedSquared' handleClick={handleGoToPick}>
              Go Back
            </Button>
            <Button
              handleClick={handleIssueSubmit}
              type='containedSquared'
              disabled={rackCode == null}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.pickSKU}>
          <div className={styles.all_picked}>All SKU's Picked</div>
          <div className={styles.submit_btn}>
            <Button type='outlinedSquared' handleClick={handleGoToPick}>
              Go Back
            </Button>
            <Button handleClick={handleSubmitSuccess} type='containedSquared'>
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PickSKUIndex;
