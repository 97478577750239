import React, { useEffect, useRef, useState } from 'react';
import styles from './ScanBarCode.module.scss';
import Quagga from 'quagga';

const ScanBarCode = ({ text, handleFunction }) => {
  const [showScanner, setShowScanner] = useState(false);
  const scannerRef = useRef(null);
  const quaggaInitialized = useRef(false);

  useEffect(() => {
    if (showScanner) {
      initializeScanner();
    }
    return () => {
      if (quaggaInitialized.current) {
        try {
          Quagga.stop();
        } catch (err) {
          console.error('Error stopping Quagga:', err);
        }
      }
    };
  }, [showScanner]);

  const initializeScanner = () => {
    if (!scannerRef.current) return;

    Quagga.init(
      {
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: scannerRef.current,
          constraints: {
            facingMode: 'environment',
          },
        },
        decoder: {
          readers: ['code_128_reader'],
          multiple: false,
        },
        locate: true,
      },
      (err) => {
        if (err) {
          console.error('Quagga initialization failed:', err);
          return;
        }
        quaggaInitialized.current = true;
        Quagga.start();
      }
    );

    Quagga.onDetected((result) => {
      if (result && result.codeResult) {
        const scannedCode = result.codeResult.code;
        handleFunction(scannedCode);
        setShowScanner(false);
      }
    });
  };

  return (
    <div>
      <button
        className={styles.scanButton}
        onClick={() => setShowScanner(true)}
      >
        {text ? text : ' Scan Barcode'}
      </button>

      {showScanner && (
        <div className={styles.scanner_container}>
          <div ref={scannerRef} className={styles.scanner_view} />
          <button
            className={styles.closeButton}
            onClick={() => setShowScanner(false)}
          >
            Close Scanner
          </button>
        </div>
      )}
    </div>
  );
};

export default ScanBarCode;
