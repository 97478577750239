import CartDetails from 'containers/QCDetails/CartDetails';
import { getOrderDetails, scanBoxCode } from 'utils/api';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'components/CustomElements/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './ScanCode.module.scss';
import { useNavigate } from 'react-router-dom';
import ScanBarCode from 'components/ScanBarCode';

const ScanCode = () => {
  const { orderId } = useParams();
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [coldItemsPresent, setColdItemsPresent] = useState(false);
  const navigate = useNavigate();

  const getDetails = async (orderId) => {
    setLoading(true);
    try {
      const response = await getOrderDetails()({ orderId });
      setDetails(response?.data);
      checkColdItems(response?.data?.orderItems);
    } catch (error) {
      enqueueSnackbar(error.response.data.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleNavigate = async (boxCode) => {
    try {
      const data = {
        orderId: Number(orderId),
        barcodeSerial: boxCode,
      };
      await scanBoxCode()(data);
      navigate(`/wms/picker/pickSKU/${orderId}/${boxCode}`);
    } catch (e) {
      enqueueSnackbar(e.response.data.message);
      console.log(e.response.data.message);
    }
  };

  const checkColdItems = (items) => {
    const check = items?.itemCartData?.some(
      (item) => item?.itemCartData?.coldStorage === true
    );
    setColdItemsPresent(check);
  };

  const getBoxType = (count) => {
    if (coldItemsPresent) {
      return 'ColdChain Item Box';
    } else if (count <= 5) {
      return 'Small Box';
    } else if (count >= 15) {
      return 'Large Box';
    } else {
      return 'Medium Box';
    }
  };

  useEffect(() => {
    getDetails(orderId);
  }, [orderId]);

  return (
    <div className={`main ${styles.scanCode}`}>
      <div className={styles.order_info}>
        <div className={styles.item}>
          <p className={styles.item__title}>Order Id:</p>
          <p className={styles.item__value}>{orderId}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.item__title}>Box Type</p>
          <p className={styles.item__value}>
            {getBoxType(details?.orderItems?.length)}
          </p>
        </div>
        <div className={styles.item}>
          <p className={styles.item__title}>Payment Type:</p>
          <p className={styles.item__value}>
            {details?.orderPaymentDetails[0]?.modeOfPayment}
          </p>
        </div>
        <div className={styles.item}>
          <p className={styles.item__title}>SKU Qty:</p>
          <p className={styles.item__value}>{details?.orderItems?.length}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.item__title}>Cold SKU's Available:</p>
          <p className={styles.item__value}>
            {coldItemsPresent ? 'Yes' : 'No'}
          </p>
        </div>
        <div className={styles.item}>
          <p className={styles.item__title}>Value:</p>
          <p className={styles.item__value}>
            {details?.orderPaymentDetails[0]?.totalAmountPayable}
          </p>
        </div>
      </div>
      <StyledAccordion
        className={styles.accordion}
        slotProps={{ transition: { unmountOnExit: true } }}
      >
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          Order Details
        </StyledAccordionSummary>
        <div className={styles.details_container}>
          <CartDetails orderDetails={details} />
        </div>
      </StyledAccordion>
      <ScanBarCode handleFunction={handleNavigate} />
    </div>
  );
};

export default ScanCode;
