'use client';
import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, styled } from '@mui/material';
import theme from 'styles/theme';

const StyledButton = styled(Button)(({ theme, styles }) => ({
  textTransform: 'unset',
  borderRadius: 100,
  ...styles,
}));

// eslint-disable-next-line react/display-name
const CustomButton = React.forwardRef(
  (
    {
      type,
      startIcon,
      children,
      handleClick,
      size,
      customStyles,
      disabled,
      loading,
    },
    ref
  ) => {
    const [styles, setStyles] = useState({});

    useEffect(() => {
      let stylesTemp = {};
      switch (type) {
        case 'outlined':
          stylesTemp = {
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.2)',
            color: 'black',
            '&:hover': {
              backgroundColor: 'white',
            },
          };
          break;
        case 'outlinedSquared':
          stylesTemp = {
            backgroundColor: 'white',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: 8,
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.2)',
            color: `${theme.palette.primary.main}`,
            '&:hover': {
              backgroundColor: 'white',
            },
          };
          break;
        case 'containedRounded':
          stylesTemp = {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          };
          break;
        case 'containedSquared':
          stylesTemp = {
            borderRadius: 8,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          };
          break;
        default:
          break;
      }
      if (disabled == true) {
        stylesTemp = {
          ...stylesTemp,
          backgroundColor: theme.palette.grey[300],
          color: theme.palette.grey[500],
          '&:hover': {
            backgroundColor: theme.palette.grey[300],
          },
        };
      }
      setStyles(stylesTemp);
    }, [type, disabled]);

    return (
      <StyledButton
        disabled={disabled}
        style={customStyles}
        size={size}
        disableRipple
        styles={styles}
        onClick={handleClick}
        variant={type}
        startIcon={startIcon}
        disableElevation
        ref={ref}
      >
        {loading && (
          <CircularProgress
            style={{ color: '#fff', marginRight: '10px' }}
            size='20px'
          />
        )}
        {children}
      </StyledButton>
    );
  }
);
CustomButton.displayName = 'CustomButton';

export default CustomButton;
