import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

import { useAuth } from '../../../context/authContext';
import './Header.css';
import ToggleThemeButton from 'components/ToggleThemeButton';
import ChangeCRMAgentStatus from 'containers/ManageTables/ChangeCRMAgentStatus/index';

function Header({ title, toggleTheme, themeMode }) {
  const { setLogin, setProfile, setPermissions } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    localStorage.removeItem('platinumRxDashboardUserDetail');
    localStorage.removeItem('platinumRxDashboardToken');
    localStorage.removeItem('user_id');
    setLogin(false);
    setProfile(null);
    setPermissions([]);
  };

  return (
    <nav className='header-nav'>
      <div className='leftContainer'>
        <KeyboardBackspaceIcon
          onClick={() => navigate(-1)}
          style={{
            cursor: 'pointer',
            borderRadius: '999px',
            border: '3px solid white',
          }}
        />
        <p>{title}</p>
      </div>
      <div className='rightContainer'>
        <ChangeCRMAgentStatus />
        <ToggleThemeButton toggleTheme={toggleTheme} themeMode={themeMode} />

        <AccountCircleIcon
          onClick={handleClick}
          style={{ marginRight: '5px' }}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </div>
    </nav>
  );
}

export default Header;
