import React from 'react';
import { useAuth } from 'context/authContext';
import { useNavigate } from 'react-router-dom';
import styles from './DefaultHome.module.scss';

function DefaultHome() {
  const { permissions } = useAuth();
  const navigate = useNavigate();

  const navigationCards = [
    {
      title: 'Orders',
      permission: [
        'FETCH_PHARMACIST_ORDERS',
        'PHARMACIST',
        'DOCTOR',
        'ASSIGN_DOCTOR',
      ],
      path: '/patients',
    },
    {
      title: 'QC Listing',
      permission: ['QC_STATUS_UPDATE'],
      path: '/qc-listing',
    },
    {
      title: 'Pending Orders',
      permission: ['FETCH_WAREHOUSE_PENDING_ORDERS'],
      path: '/procurement-dashboard/pending-orders',
    },
    {
      title: 'Tasks',
      permission: ['FETCH_ALL_TASKS', 'FETCH_TASKS'],
      path: permissions.includes('FETCH_ALL_TASKS')
        ? '/crm-dashboard/tasks-admin'
        : '/crm-dashboard/tasks',
    },
    {
      title: 'Picker',
      permission: ['UPDATE_BARCODE_SERIAL'],
      path: '/wms/picker/acceptOrder',
    },
    {
      title: 'Picker TL',
      permission: ['FETCH_PICKING_ISSUE_ORDERS'],
      path: '/wms/picker/tl/order-listing',
    },
  ];

  return (
    <div className={styles.default_home}>
      <h1>Welcome to Dashboard</h1>
      <div className={styles.navigation_grid}>
        {navigationCards.map(
          (card) =>
            card.permission.some((p) => permissions.includes(p)) && (
              <div
                key={card.title}
                className={styles.nav_card}
                onClick={() => navigate(card.path)}
              >
                <h2>{card.title}</h2>
              </div>
            )
        )}
      </div>
    </div>
  );
}

export default DefaultHome;
