import { useState, useEffect } from 'react';
import { Collapse, Typography, IconButton, Paper } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { getEvents } from 'utils/api';
import {
  getFormattedDateWithMonthName,
  getTimestamp,
  getWeekDay,
} from 'utils/commonFunctions';
import { renderSpecificEventContent } from './getSpecificEventContent';
import styles from './leadHistory.module.scss';
import { getEventIcon } from './utils';
import PageLoader from 'components/Loaders/ComponentLoader';

const RenderActivityTimeline = ({ userId, eventRanks, isActive }) => {
  const [events, setEvents] = useState([]);
  const [expandedEvents, setExpandedEvents] = useState({});
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEventToggle = (eventId) => {
    setExpandedEvents((prev) => ({
      ...prev,
      [eventId]: !prev[eventId],
    }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = {
        params: { limit: 50, skip: 0 },
        data: {
          eventRanks: eventRanks,
          userId: Number(userId),
        },
      };
      const eventsResponse = await getEvents()(data);
      setEvents(eventsResponse.data.data.events);
      setHasLoaded(true);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId && isActive && !hasLoaded) {
      fetchData();
    }
  }, [userId, isActive, hasLoaded, eventRanks]);

  const renderEventDetails = (event) => {
    const isExpanded = expandedEvents[event?.id];
    const eventContent = renderSpecificEventContent(event);
    const hasContent = eventContent?.props?.children?.length > 0;

    return (
      <Paper elevation={0} className={styles.eventDetailsWrapper}>
        <div
          className={styles.eventHeader}
          onClick={() => hasContent && handleEventToggle(event?.id)}
          style={{ cursor: hasContent ? 'pointer' : 'default' }}
        >
          <div>
            <p className={styles.eventTitle}>{event?.eventName}</p>
            <p className={styles.eventSubtitle}>
              {hasContent && eventContent?.props?.children[0]}
            </p>
          </div>
          {hasContent && (
            <IconButton size='small' className={styles.expandButton}>
              {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          )}
        </div>

        {hasContent && (
          <Collapse in={isExpanded} slot='content'>
            <p className={styles.eventContent}>
              {eventContent?.props?.children[1]}
            </p>
          </Collapse>
        )}
      </Paper>
    );
  };

  const groupedEvents = events.reduce((acc, event) => {
    const date =
      getWeekDay(event?.createdAt) +
      ' ' +
      getFormattedDateWithMonthName(event?.createdAt);
    if (!acc[date]) acc[date] = [];
    acc[date].push(event);
    return acc;
  }, {});

  return (
    <div className={styles.timelineContainer}>
      {isLoading ? (
        <PageLoader />
      ) : (
        Object.entries(groupedEvents).map(([date, dateEvents]) => (
          <div key={date} className={styles.timelineGroup}>
            <p className={styles.timelineDate}>{date}</p>
            <div className={styles.timelineEvents}>
              {dateEvents.map((event) => (
                <div key={event?.id} className={styles.timelineEvent}>
                  <div className={styles.timelineTime}>
                    <Typography variant='caption'>
                      {getTimestamp(event?.createdAt)}
                    </Typography>
                  </div>
                  <div className={styles.timelineConnector}>
                    <div className={styles.connectorDot}>
                      {getEventIcon(event?.eventName)}
                    </div>
                    <div className={styles.connectorLine} />
                  </div>
                  <div className={styles.timelineContent}>
                    {renderEventDetails(event)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default RenderActivityTimeline;
