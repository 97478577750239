import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { getCustomerTasks } from 'utils/api';

const CustomerTasks = ({ userId, activeTab }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const statusOptions = {
    1: 'PENDING',
    5: 'ASSIGNED',
    10: 'IN_PROGRESS',
    20: 'COMPLETED',
    50: 'CLOSED',
  };

  const fetchTasks = async () => {
    setLoading(true);

    try {
      const data = {
        data: {
          userId: Number(userId),
        },
        params: { limit: 50, skip: 0 },
      };

      const response = await getCustomerTasks()(data);

      setTasks(response.data.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId && activeTab === 'tasks') {
      fetchTasks();
    }
  }, [userId, activeTab]);

  const rows = tasks.map((task) => ({
    id: task.id,
    name: task.name,
    campaignId: task.campaignId || 'N/A',
    userId: task.userId || 'N/A',
    activeCartValue: task.activeCartValue,
    status: statusOptions[task.status] || 'N/A',
    createdAt: new Date(task.createdAt).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
    agentName: task.agent?.username || '-',
  }));

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Task Name', width: 200 },
    { field: 'userId', headerName: 'User ID', width: 120 },
    { field: 'activeCartValue', headerName: 'Active Cart Value', width: 160 },
    { field: 'status', headerName: 'Status', width: 120 },
    { field: 'createdAt', headerName: 'Created At', width: 180 },
    { field: 'agentName', headerName: 'Agent Name', width: 150 },
  ];

  return (
    <div>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        loading={loading}
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f5f5f5',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#f5f5f5',
          },
        }}
      />
    </div>
  );
};

export default CustomerTasks;
