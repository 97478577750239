export const vendorOptions = [
  { id: '1614352000000145909', name: 'Dinesh Pharma Sales' },
  { id: '1614352000000289045', name: 'Focus Medisales Pvt Ltd' },
  { id: '1614352000000359939', name: 'GANESH MEDICAL' },
  { id: '1614352000000289109', name: 'Generica India Limited' },
  { id: '1614352000000581950', name: 'HEXACARE PHARMACEUTICALS' },
  { id: '1614352000000832788', name: 'Janatha Pharma' },
  { id: '1614352000000223778', name: 'Jupiter Pharma' },
  { id: '1614352000002003826', name: 'Laxmi Healthcare' },
  { id: '1614352000002003931', name: 'Laxmi Hospicare LLP' },
  { id: '1614352000001502997', name: 'Laxmi Pharma' },
  { id: '1614352000000289169', name: 'Mahaveer Medi Sales Pvt Ltd' },
  { id: '1614352000001580872', name: 'MAHAVEER MEDICARE (117)' },
  { id: '1614352000000828446', name: 'Med Pharma' },
  { id: '1614352000000581730', name: 'Mini International' },
  { id: '1614352000003104171', name: 'PHARMA HUB' },
  { id: '1614352000000577376', name: 'pharma link' },
  { id: '1614352000000980278', name: 'PharmaXYL Drug' },
  { id: '1614352000001311732', name: 'Pradeep Distributors' },
  {
    id: '1614352000000289141',
    name: 'Truemeds - Intellihealth Solutions Pvt Ltd',
  },
  { id: '1614352000001381864', name: 'Udaan' },
  { id: '1614352000000289013', name: 'Vardhman Medisales Pvt Ltd' },
  { id: '1614352000001311848', name: 'VPI MEDISALES' },
  { id: '1614352000098390008', name: 'No Stock' },
  { id: '1614352000105831503', name: 'Prx Internal Transfer' },
];

export const today = new Date().toISOString().split('T')[0];

export const agentsBasedOnRoleId = {
  CRM_Agent: 19,
};
