import React, { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';

import {
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './DraftPOView.module.scss';
import {
  bulkClosePOs,
  createDraftPurchaseOrder,
  createPurchaseOrder,
  deleteDraftPurchaseOrderItem,
  draftPurchaseOrderItem,
  UploadExcelFile, // Import the UploadExcelFile API
} from 'utils/api';
import { useNavigate } from 'react-router-dom';
import { vendorOptions } from 'utils/constants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DraftPOView = () => {
  const [loading, setLoading] = useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [draftItems, setDraftItems] = useState([]);
  const [masterDrugCodes, setMasterDrugCodes] = useState('');
  const [selectedZohoIds, setSelectedZohoIds] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null); // State for file upload
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // Add state for confirmation dialog
  const [selectedRows, setSelectedRows] = useState({});
  const navigate = useNavigate();

  const fetchDraftPurchaseOrderItems = async () => {
    try {
      let data = {
        data: {
          masterDrugCodes: masterDrugCodes
            .split(',')
            .map((drugCode) => parseInt(drugCode))
            .filter(Boolean),
          vendorZohoIds: selectedZohoIds.map((option) => option.id),
        },
        params: {
          skip: 0,
          limit: 1500,
        },
      };
      const response = await draftPurchaseOrderItem()(data);
      if (response) {
        setDraftItems(response.data.draftPurchaseOrderItems);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const handleEditClick = (id) => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: 'edit' },
    }));
  };

  const handleSaveClick = (id) => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: 'view' },
    }));
  };

  const handleDeleteClick = async (id) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this item?'
    );
    if (!confirmed) return;

    try {
      setLoading(true);
      const response = await deleteDraftPurchaseOrderItem()(id);
      if (response.data.success === 1) {
        fetchDraftPurchaseOrderItems();
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    } finally {
      setLoading(false);
    }
  };

  const processRowUpdate = async (updatedItem, oldItem) => {
    try {
      setLoading(true);
      const payload = {
        masterDrugCode: updatedItem.masterDrugCode,
        quantity: parseInt(updatedItem.quantity, 10),
        purchaseRate: parseFloat(updatedItem.purchaseRate),
        eta: updatedItem.eta,
        vendorZohoId: updatedItem.vendorZohoId,
      };

      const response = await createDraftPurchaseOrder()(payload);

      if (response.data.success === 1) {
        fetchDraftPurchaseOrderItems();
        setRowModesModel((prev) => ({
          ...prev,
          [updatedItem.id]: { mode: 'view' },
        }));
      }
    } catch (error) {
      console.log('Error saving item:', error);
      return oldItem;
    } finally {
      setLoading(false);
    }

    return updatedItem;
  };

  const handleBulkMarkAsClosed = async () => {
    try {
      setLoading(true);
      const data = {
        ids: selectedRows,
      };
      await bulkClosePOs()(data);
      fetchDraftPurchaseOrderItems();
      setSelectedRows([]);
      setConfirmDialogOpen(false);
    } catch (error) {
      console.error('Error marking items as closed:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await createPurchaseOrder()();
      navigate('/procurement-dashboard/purchase-orders');
    } catch (error) {
      console.error('Error submitting draft P.O.:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleFileSubmit = async () => {
    if (!uploadedFile) {
      alert('Please upload a file first.');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', uploadedFile);
      const response = await UploadExcelFile()(formData);
      if (response?.data?.success === 1) {
        fetchDraftPurchaseOrderItems();
        alert('File uploaded successfully!');
        setUploadedFile(null);
      } else {
        alert('File upload failed.');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('There was an error uploading the file. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  useEffect(() => {
    fetchDraftPurchaseOrderItems();
  }, [masterDrugCodes, selectedZohoIds]);

  const columns = [
    {
      field: 'masterDrugCode',
      headerName: 'Drug Code',
      width: 100,
      type: 'number',
      valueFormatter: (row, value) => {
        return row ? row.toString() : '';
      },
    },
    {
      field: 'quantity',
      type: 'number',
      headerName: 'Quantity',
      width: 100,
      editable: true,
    },
    {
      field: 'purchaseRate',
      headerName: 'Purchase Rate',
      width: 120,
      type: 'number',
      editable: true,
    },
    {
      field: 'vendorZohoId',
      headerName: 'Vendor',
      width: 270,
      renderCell: (params) => {
        const vendor = vendorOptions.find((v) => v.id === params.value);
        return vendor ? vendor.name : 'Unknown';
      },
      // renderEditCell: (params) => (
      //   <FormControl fullWidth>
      //     <InputLabel>Vendor</InputLabel>
      //     <Select
      //       value={params.value}
      //       onChange={(event) => {
      //         params.api.setEditCellValue({
      //           id: params.id,
      //           field: 'vendorZohoId',
      //           value: event.target.value,
      //         })
      //       }}
      //       label="Vendor"
      //     >
      //       {vendorOptions.map((vendor) => (
      //         <MenuItem key={vendor.id} value={vendor.id}>
      //           {vendor.name}
      //         </MenuItem>
      //       ))}
      //     </Select>
      //   </FormControl>
      // ),
      // editable: true,
    },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 150,
      editable: true,
      renderEditCell: (params) => (
        <DatePicker
          value={dayjs(params.value)}
          onChange={(newValue) => {
            params.api.setEditCellValue({
              id: params.id,
              field: 'eta',
              value: newValue?.format('YYYY-MM-DD'),
            });
          }}
          renderInput={(inputProps) => <TextField {...inputProps} />}
          minDate={dayjs()}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      type: 'actions',
      getActions: (params) => {
        const isInEditMode = rowModesModel[params.id]?.mode === 'edit';
        return isInEditMode
          ? [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label='Save'
                onClick={() => {
                  handleSaveClick(params.id);
                }}
                color='primary'
              />,
            ]
          : [
              <GridActionsCellItem
                icon={<EditIcon />}
                label='Edit'
                onClick={() => handleEditClick(params.id)}
                color='inherit'
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label='Delete'
                onClick={() => handleDeleteClick(params.id)}
                color='inherit'
              />,
            ];
      },
    },
  ];

  const rows = draftItems.map((item) => ({
    id: item.id,
    masterDrugCode: item.masterDrugCode,
    quantity: item.quantity,
    purchaseRate: item.purchaseRate,
    vendorZohoId: item.vendorZohoId,
    eta: item.eta,
    createdAt: new Date(item.createdAt).toLocaleString(),
    updatedAt: new Date(item.updatedAt).toLocaleString(),
  }));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='home'>
        <div className={styles.headerContainer}>
          <h1>
            <b>Requested P.O.'s</b>
          </h1>
          <div className={styles.uploadContainer}>
            <a
              href='https://docs.google.com/spreadsheets/d/1DdsPOkcrgT_KipVUKaawPDoVyIiI5NfSxgtWF1eqUpI/edit?gid=0#gid=0'
              target='_blank'
              rel='noopener noreferrer'
              className={styles.sampleFileLink}
            >
              <u>Sample File</u>
            </a>
            {!uploadedFile ? (
              <Button
                variant='contained'
                component='label'
                className={styles.uploadButton}
              >
                Upload File
                <input
                  type='file'
                  hidden
                  accept='.csv'
                  onChange={handleFileUpload}
                />
              </Button>
            ) : (
              <div className={styles.fileInfo}>
                <p>
                  <b>Uploaded File:</b> {uploadedFile.name}
                </p>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleFileSubmit}
                  className={styles.submitButton}
                >
                  Submit File
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className='filter-header'>
          <TextField
            variant='outlined'
            fullWidth
            label='Enter master drug code (Comma separated if multiple)'
            onChange={(e) => setMasterDrugCodes(e.target.value)}
          />
          <Autocomplete
            multiple
            options={vendorOptions}
            getOptionLabel={(option) => option.name}
            value={selectedZohoIds}
            onChange={(event, newValue) => setSelectedZohoIds(newValue)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Select Zoho Vendors'
                variant='outlined'
              />
            )}
            style={{ width: '90%', borderRadius: '15px' }}
          />
          {selectedRows.length > 0 && ( // Show button if there are selected rows
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={() => setConfirmDialogOpen(true)} // Open confirmation dialog
            >
              Mark Selected as Closed ({selectedRows.length})
            </Button>
          )}
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={100}
          loading={loading}
          autoHeight
          editMode='row'
          slots={{ toolbar: GridToolbar }}
          processRowUpdate={processRowUpdate}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          disableSelectionOnClick={false}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection); // Update selected rows
          }}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'var(--grey-2)',
              fontWeight: 'bold',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
            '& .MuiDataGrid-footerContainer': {
              position: 'sticky',
              bottom: 0,
              zIndex: 1,
              backgroundColor: 'var(--grey-2)',
            },
          }}
        />
        <div className='footer'>
          <Button onClick={handleSubmit} variant='contained' disabled={loading}>
            Submit
          </Button>
        </div>
        <Dialog
          open={confirmDialogOpen} // Open confirmation dialog
          onClose={() => setConfirmDialogOpen(false)}
        >
          <div className={styles.confirmDialog}>
            <DialogTitle>Confirm Action</DialogTitle>
            <DialogContent>
              Are you sure you want to mark {selectedRows.length} items as
              closed?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDialogOpen(false)}>
                Cancel
              </Button>
              <Button onClick={handleBulkMarkAsClosed} color='primary'>
                Confirm
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </LocalizationProvider>
  );
};

export default DraftPOView;
