import { createContext, useContext, useState } from 'react';
const AppContext = createContext();

function AppProvider({ children }) {
  const [cartData, setCartData] = useState([]);
  const [digitizedPrx, setDigitizedPrx] = useState([]);
  const [doctorCart, setDoctorCart] = useState([]);
  const [campaignIds, setCampaignIds] = useState([]);
  const [statusFilters, setStatusFilters] = useState([5, 10]);
  const [agentFilter, setAgentFilter] = useState([]);
  const [minCartValue, setMinCartValue] = useState(null);
  const [maxCartValue, setMaxCartValue] = useState(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState('');

  return (
    <AppContext.Provider
      value={{
        cartData,
        setCartData,
        digitizedPrx,
        setDigitizedPrx,
        doctorCart,
        setDoctorCart,
        campaignIds,
        setCampaignIds,
        statusFilters,
        setStatusFilters,
        agentFilter,
        setAgentFilter,
        minCartValue,
        setMinCartValue,
        maxCartValue,
        setMaxCartValue,
        userPhoneNumber,
        setUserPhoneNumber,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => {
  return useContext(AppContext);
};

export default AppProvider;
