export const getFormattedDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const getFormattedDateWithMonthName = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date
    .toLocaleString('default', { month: 'long' })
    .substring(0, 3);
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};

export const getWeekDay = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.toLocaleString('default', { weekday: 'long' });
  return day;
};

export const getTimestamp = (time) => {
  const dateObj = new Date(time);
  const istTime = dateObj.toLocaleString('en-US', {
    timeZone: 'Asia/Kolkata',
    hour: '2-digit',
    minute: '2-digit',
  });
  return istTime;
};

export const handlePdpRedirect = (name, code) => {
  const url_name = name?.toLowerCase().replace(/[^a-zA-Z0-9]+/g, '-');
  const encodedMasterDrugCode = encodeURIComponent(code);
  console.log(`/product-display/${url_name}/${encodedMasterDrugCode}`);
  window.open(
    `https://www.platinumrx.in/product-display/${url_name}/${encodedMasterDrugCode}`,
    '_blank'
  );
};
