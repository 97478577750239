import { createContext, useContext, useState } from 'react';
const UserContext = createContext();

function UserProvider({ children }) {
  const [doctorMap, setDoctorMap] = useState({});
  const [pharmacistMap, setPharmacistMap] = useState({});
  const [taskId, setTaskId] = useState(null);

  return (
    <UserContext.Provider
      value={{
        doctorMap,
        setDoctorMap,
        pharmacistMap,
        setPharmacistMap,
        taskId,
        setTaskId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => {
  return useContext(UserContext);
};

export default UserProvider;
